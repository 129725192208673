import { useEffect, useState } from 'react'
import { ValidationObject } from './types'
import { DepthAnalysisSections } from '../../api/inDepthAnalysis/types'

export const useCalculateRisk = (riskValues: ValidationObject, form: DepthAnalysisSections[] | undefined) => {
    const [riskAssessment, setRiskAssessment] = useState({ risk: 'NONE', text: '', color: '#fff' })

    const getOptionValue = (sections: DepthAnalysisSections[] | undefined, questionId: number, optionId: number) => {
        if (sections)
            for (const section of sections) {
                for (const question of section.questions) {
                    if (question.id === questionId) {
                        const option = question.options.find((opt: { id: number }) => opt.id === optionId)
                        return option ? option.value : null
                    }
                }
            }
        return null
    }

    useEffect(() => {
        const { low, high, veryHigh } = Object.entries(riskValues).reduce(
            (acc, [key, value]) => {
                if (getOptionValue(form, Number(key.split('_')[1]), Number(value)) === 'true') {
                    if (key.includes('LOW')) acc.low++
                    if (key.includes('HIGH')) acc.high++
                    if (key.includes('VERY_HIGH')) acc.veryHigh++
                }
                return acc
            },
            { low: 0, high: 0, veryHigh: 0 }
        )

        let riskAssessment

        if (veryHigh > 0) {
            riskAssessment = { risk: 'HIGH', text: 'Visoki', color: '#e82c2c' }
        } else if (high > low) {
            riskAssessment = { risk: 'HIGH', text: 'Visoki', color: '#e82c2c' }
        } else if (high < low) {
            riskAssessment = { risk: 'LOW', text: 'Niski', color: '#0ab39c' }
        } else if (high === low && high !== 0) {
            riskAssessment = { risk: 'MEDIUM', text: 'Srednji', color: '#f7b84b' }
        } else {
            riskAssessment = { risk: 'NONE', text: '', color: '#fff' }
        }

        setRiskAssessment(riskAssessment)
    }, [form, riskValues])

    return { riskAssessment }
}
