import { values } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from '../../../Components/Table/TableContainer'
import {  getAgentActivitiesReportSum, totalReducer } from '../utils/report'

export const AgentActivitiesReport = ({ data, reportsFetching }) => {
    const { t } = useTranslation()

    const reports = values(data)
    const activityTypes = reports[0]?.activities

    const columns = useMemo(() => {
        if (activityTypes?.length > 0) {
            const agentColumn = {
                Header: t('app.common.agent'),
                id: 'agent',
                accessor: ({ agent }) => `${agent.firstName} ${agent.lastName}`,
                filterable: false,
            }

            const activityColumns = activityTypes.map(({ id, name }) => ({
                Header: name,
                id: `${id}`,
                accessor: ({ activities }) => {
                    return activities?.find((activity) => activity?.id === id)?.count
                },
                filterable: false,
            }))

            const totalColumn = {
                Header: t('app.common.total'),
                id: 'total_by_agent',
                accessor: ({ activities }) => {
                    const total = activities.reduce(totalReducer, 0)

                    return <strong>{total}</strong>
                },
                filterable: false,
            }

            return [agentColumn, ...activityColumns, totalColumn]
        }

        return []
    }, [activityTypes, t])

    const agentActivitiesReportSum = useMemo(() => getAgentActivitiesReportSum(reports), [reports])

    const tableFooter = useMemo(() => {
        if (!agentActivitiesReportSum) return null

        const agentActivitiesReportSumTotal = agentActivitiesReportSum.reduce((acc, curr) => acc + curr.count, 0)

        return (
            <tr>
                <td>{t('app.common.overall')}</td>
                {agentActivitiesReportSum.map((activity) => (
                    <td key={activity.id}>{activity.count}</td>
                ))}
                <td className="fw-bolder">{agentActivitiesReportSumTotal}</td>
            </tr>
        )
    }, [agentActivitiesReportSum, t])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            className="custom-header-css"
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            tableFooter={tableFooter}
            hidePagination
        />
    )
}
