import React, { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { ReportType } from './types'

const ReportTypes = [
    { id: ReportType.agentActivities, name: 'Aktivnosti agenta' },
    // { id: ReportType.agentRevenue, name: 'Prihodi agenta' },
    // { id: ReportType.agentRevenueBySource, name: 'Prihodi agenta po izvoru podataka' },
    // { id: ReportType.agentInquiryByContactSource, name: `Broju upita po podatku “Kako se saznalo”` },
    // { id: ReportType.agentInquiryByDataSource, name: `Broju upita po podatku “Izvor podataka”` },
]

const SelectReport = () => {
    const { params } = useRouteMatch()
    const history = useHistory()

    const reportType = useMemo(() => {
        if (!params.reportType) return null

        return ReportTypes.find(({ id }) => id === params.reportType)
    }, [params])

    return (
        <div className="d-flex align-items-center">
            <UncontrolledDropdown className="me-4">
                <DropdownToggle className="btn btn-primary" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu>
                    {ReportTypes.map(({ id, name }) => (
                        <DropdownItem key={id} onClick={() => history.push(id)} active={id === reportType?.id}>
                            {name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            {reportType?.name}
        </div>
    )
}

export default SelectReport
