import QueryString from 'qs'
import { apiReq } from '..'
import { PageListParams } from '../globalTypes'

export const getFormsSubmission = async (id: string, payload: PageListParams) => {
    const response = await apiReq(`v1/form-submission/contact/${id}`, {
        params: payload,
        paramsSerializer: (params: PageListParams) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}

export const getFormSubmissionById = async (id: string) => {
    if (id) {
        const response = await apiReq(`v1/form-submission/${id}`)

        return response?.data
    } else {
        return []
    }
}

export const getFormById = async (id: number) => {
    const response = await apiReq(`v1/form/${id}`)
    return response?.data
}

export const createUpdateFormsSubmissions = async (data: any, fromSubmissionId?: string, isEdit?: boolean) => {
    const url = isEdit ? `v1/form-submission/${fromSubmissionId}` : `v1/form-submission`

    const response = await apiReq(url, {
        method: isEdit ? 'PUT' : 'POST',
        data: data,
    })

    if (response.status !== 200 && response.status !== 201) {
        throw new Error()
    }

    return response?.data
}

export const deleteFormsSubmissionById = async (id?: number) => {
    const response = await apiReq(`v1/form-submission/${id}`, {
        method: 'DELETE',
    })

    if (response.status !== 200) {
        throw new Error()
    }

    return response?.data
}

export const getAnalysisPdf = async (id: string | undefined) => {
    const response = await apiReq(`v1/form-submission/${id}/pdf`)

    const byteCharacters = atob(response.data.pdf_base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' })
    const pdfBlobUrl = URL.createObjectURL(pdfBlob)

    return pdfBlobUrl
}
