import { values } from 'lodash'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { colors } from '../constants'
import { handleChartHeight, getAgentActivitiesReportSum } from '../utils/report'

const AgentActivitiesReportChart = ({ data }) => {
    const { t } = useTranslation()
    const overallLabel = t('app.common.overall')
    const totalLabel = t('app.common.total')

    const reports = values(data)

    const { formattedData, agentTotals } = useMemo(() => {
        return reports?.reduce(
            (acc, curr) => {
                const { agent, activities } = curr
                const agentName = `${agent?.firstName} ${agent?.lastName}`
                acc.agentTotals[agentName] = 0

                activities?.forEach((activity) => {
                    acc.agentTotals[agentName] += activity.count
                    const existingActivity = acc.formattedData.find((item) => item.id === activity.id)

                    if (existingActivity) {
                        existingActivity[agentName] = activity.count
                    } else {
                        acc.formattedData.push({
                            id: activity.id,
                            name: activity.name,
                            [agentName]: activity.count,
                        })
                    }
                })

                return acc
            },
            {
                formattedData: [],
                agentTotals: {},
            }
        )
    }, [reports])

    const activitySums = useMemo(() => {
        return getAgentActivitiesReportSum(reports)
    }, [reports])

    // adds activity sums (overall field) to formattedData objects
    const dataWithOveralls = useMemo(() => {
        return formattedData.map((data) => {
            const activitySumObj = activitySums.find((activity) => activity.id === data.id)

            return {
                ...data,
                [overallLabel]: activitySumObj?.count || 0,
            }
        })
    }, [formattedData, activitySums, overallLabel])

    const totalsObj = useMemo(
        () => ({
            id: 'Ukupno',
            name: totalLabel,
            ...agentTotals,
            [overallLabel]: activitySums.reduce((acc, curr) => acc + curr.count, 0),
        }),
        [agentTotals, activitySums, totalLabel, overallLabel]
    )

    const numObjects = formattedData.length
    const numFields = Object.keys(formattedData[0]).length - 2 // exclude id, name
    const totalHeight = handleChartHeight(numObjects, numFields)

    // when filter is for single agent or single activity, no need to display totals & overalls in chart
    const excludeTotal = formattedData.length === 1 || Object.keys(agentTotals).length === 1
    const chartData = excludeTotal ? formattedData : [totalsObj, ...dataWithOveralls]

    return (
        <div style={{ height: `${totalHeight}px` }} className="w-full">
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={chartData}
                    barSize={15}
                    barGap={5}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 100,
                        bottom: 50,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tickMargin={10} />
                    <Tooltip />
                    <Legend iconSize={16} />

                    {!excludeTotal && (
                        <Bar key="overall" dataKey={overallLabel} fill={colors[0]}>
                            <LabelList dataKey={overallLabel} position="right" fill="#000" fontSize={12} />
                        </Bar>
                    )}

                    {reports?.map((data, index) => (
                        <Bar key={data.agent.id} dataKey={`${data.agent.firstName} ${data.agent.lastName}`} fill={colors[index + 1]}>
                            <LabelList
                                dataKey={`${data.agent.firstName} ${data.agent.lastName}`}
                                position="right"
                                fill="#000"
                                fontSize={12}
                                formatter={(value) => (value > 0 ? value : '')}
                            />
                        </Bar>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    )
}

export default AgentActivitiesReportChart
