import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getReportByType } from './functions'
import { ReportCriteria, ReportResponse } from './types/global.types'

const reportTypes = {
    getReport: {
        useQuery: (type: string, criteria: ReportCriteria, refreshToken: string, options?: UseQueryOptions<ReportResponse, AxiosError>) => {
            return useQuery({
                queryKey: [`${type}`, criteria, refreshToken],
                queryFn: () => getReportByType(type, criteria),
                // staleTime: 60 * 1000,
                ...options,
            })
        },
    },
}

export default reportTypes
