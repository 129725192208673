import { DepthAnalysis, DepthAnalysisSections, DepthAnalysisQuestions, FormSubmissionAnswers } from '../../api/inDepthAnalysis/types'
import { Ids } from './types'

export const findConnectedQuestionsIdsToDelete = (data: DepthAnalysis | undefined) => {
    const ids: Ids[] = []

    data?.sections?.forEach((section) => {
        const obj: Ids = {
            questionsToDelete: [],
            section: 0,
        }
        section?.questions?.forEach((question) => {
            question?.options?.forEach((option) => {
                if (option.sectionVisibilityToggleId !== null) {
                    obj[`questionId_${question.id}`] = question.id
                    obj[`section`] = option.sectionVisibilityToggleId
                    obj['questionsToDelete'] = findQuestionIdsInSection(data, option.sectionVisibilityToggleId)
                    ids.push(obj)
                }
            })
        })
    })

    const areObjectsEqual = (obj1: Ids, obj2: Ids) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    const filteredIds = ids.filter((item, index, self) => {
        return !self.some((otherItem, otherIndex) => {
            return otherIndex < index && areObjectsEqual(otherItem, item)
        })
    })

    const updatedData = filteredIds.map((item) => {
        let mergedQuestionsToDelete = [...item.questionsToDelete]
        let setionToHide = [item.section]

        filteredIds.forEach((otherItem) => {
            const questionId = Object.values(otherItem)[0]
            if (mergedQuestionsToDelete.includes(questionId)) {
                mergedQuestionsToDelete = [...new Set([...mergedQuestionsToDelete, ...otherItem.questionsToDelete])]
                setionToHide = [...new Set([...setionToHide, otherItem.section])]
            }
        })

        return { ...item, questionsToDelte: mergedQuestionsToDelete, section: setionToHide }
    })

    return updatedData
}

export const findQuestionIdsInSection = (data: { sections: DepthAnalysisSections[] }, sectionId: number) => {
    const section = data.sections?.find((sec) => sec.id === sectionId)
    return section ? section.questions?.map((questions: { id: number }) => questions.id) : []
}

export function findObjectByQuestionId(arr: any[], targetId: number) {
    return arr?.find((obj) => Object.entries(obj).some(([key, value]) => key.startsWith('questionId_') && value === targetId))
}

export const parseNumber = (formattedValue: string) => {
    return formattedValue.replace(/\./g, '').replace(',', '.')
}

export const convertEndpointDataInFormikData = (data: FormSubmissionAnswers[] | undefined) => {
    return data?.reduce((acc, { question_id, type, riskFactor, selectedOption, stringValue, dateValue, floatValue, textValue }) => {
        const key = `question_${question_id}_${type}_${riskFactor}`
        acc[key] = selectedOption || stringValue || dateValue || floatValue || textValue
        return acc
    }, {})
}

export const updateRequiredInHiddenSections = (sections: DepthAnalysisSections[] | undefined) => {
    const deepSections = sections && JSON.parse(JSON.stringify(sections))
    const arr = deepSections?.map((section: { hidden: boolean; questions: DepthAnalysisQuestions[] }) =>
        section.hidden
            ? {
                  ...section,
                  questions: section.questions.map((question) => ({
                      ...question,
                      required: false,
                  })),
              }
            : section
    )
    return arr ?? null
}

export const updateSectionsVisibility = (sections: DepthAnalysisSections[] | undefined, answers: FormSubmissionAnswers[] | undefined) => {
    const deepSections = sections && JSON.parse(JSON.stringify(sections))
    deepSections?.map((section: DepthAnalysisSections) => {
        if (section.questions?.some((question) => answers?.some((answer) => answer.question_id === question.id))) {
            return { ...section, hidden: false }
        }
        return section
    })
    return updateRequiredInHiddenSections(deepSections)
}
