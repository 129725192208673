import dayjs from 'dayjs'

export const defaultStartDate = dayjs().subtract(30, 'days').format('YYYY-MM-DD')
export const defaultEndDate = dayjs().format('YYYY-MM-DD')
export const dataFallback = '--'

export const colors = [
    'darkturquoise',
    'lightskyblue',
    'gold',
    'lightpink',
    'crimson',
    'cornflowerblue',
    'mediumpurple',
    'sandybrown',
    'lightcoral',
    'lightgreen',
    'darkorchid',
    'silver',
    'palevioletred',
    'palegoldenrod',
    'palegreen'
]

