import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Card, CardBody, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import reportTypes from '../../api/reports/hooks'
import { contactResponseFields } from '../../common/response/contact'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import { selectContactSourceValues } from '../../store/contactValues'
import { selectPropertyValuesDataSource } from '../../store/propertyValues/selectors'
import { formatDateTime } from '../../utils/general'
import { AgentActivitiesReport } from './AgentActivitiesReport/AgentActivitiesReport'
import AgentActivitiesReportChart from './AgentActivitiesReport/AgentActivitiesReportChart'
import { AgentInquiriesReport } from './AgentInquiriesReport'
import { AgentRevenueBySourceReport } from './AgentRevenueBySourceReport'
import { AgentRevenueReport } from './AgentRevenueReport'
import { defaultEndDate, defaultStartDate } from './constants'
import ReportListFilter from './ReportListFilter'
import SelectReport from './SelectReport'
import { ReportType } from './types'

const ReportRoute = {
    [ReportType.agentActivities]: AgentActivitiesReport,
    // [ReportType.agentRevenue]: AgentRevenueReport,
    // [ReportType.agentRevenueBySource]: AgentRevenueBySourceReport,
    // [ReportType.agentInquiryByContactSource]: AgentInquiriesReport,
    // [ReportType.agentInquiryByDataSource]: AgentInquiriesReport,
}

const ReportChartRoute = {
    [ReportType.agentActivities]: AgentActivitiesReportChart,
    // [ReportType.agentRevenue]: AgentRevenueReport,
    // [ReportType.agentRevenueBySource]: AgentRevenueBySourceReport,
    // [ReportType.agentInquiryByContactSource]: AgentInquiriesReport,
    // [ReportType.agentInquiryByDataSource]: AgentInquiriesReport,
}

const ReportList = () => {
    const { t } = useTranslation()

    document.title = `${t('app.common.reports')} | ${t('app.title')}`

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    // TODO: add more filters/criteria
    const [searchCriteria, setSearchCriteria] = useState({
        dateFrom: defaultStartDate,
        dateTo: defaultEndDate,
        agent: [],
        activityType: [],
    })

    const refreshToken = localStorage.getItem('refresh_token')

    const {
        path,
        params: { reportType },
    } = useRouteMatch()

    const { data, isLoading: reportsFetching } = reportTypes.getReport.useQuery(`${reportType}`, searchCriteria, refreshToken)

    const handleFilterSubmit = (formValues) => {
        const { dateFrom, dateTo, agent, activityType } = formValues

        setSearchCriteria({ dateFrom, dateTo, agent, activityType })
        setIsDropdownOpen(false)
    }

    const handleFilterReset = () => {
        setIsDropdownOpen(false)
    }

    const currentDateRange = useMemo(() => {
        if (searchCriteria) {
            const { dateFrom, dateTo } = searchCriteria

            const formattedDateFrom = formatDateTime(dateFrom)
            const formattedDateTo = formatDateTime(dateTo)

            return `[${formattedDateFrom} - ${formattedDateTo}]`
        }

        return null
    }, [searchCriteria])

    const Report = ReportRoute[reportType]
    const ReportChart = ReportChartRoute[reportType]

    const tableActions = (
        <Row>
            <Col>
                <SelectReport />
            </Col>
            <Col xs="auto" className="ms-auto align-self-center">
                {currentDateRange}
            </Col>
            <Col xs="auto">
                {/* TODO: toggle?  */}

                <Dropdown isOpen={isDropdownOpen} toggle={() => ({})}>
                    <DropdownToggle className="btn btn-primary" tag="button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <i className="mdi mdi-filter-variant align-middle pe-none"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-form">
                        <ReportListFilter value={searchCriteria} onFilterSubmit={handleFilterSubmit} onFilterReset={handleFilterReset} />
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row>
    )

    return (
        <div className="page-content">
            <Container fluid>
                <PageTitle title={t('app.common.reports')} />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                {reportsFetching ? <Loader /> : <ReportChart data={data} />}
                                {tableActions}
                                <Switch>
                                    <Route
                                        path={path}
                                        render={() => {
                                            if (!reportType) {
                                                return <Redirect to={`/reports/${ReportType.agentActivities}`} />
                                            }

                                            if (reportType === ReportType.agentInquiryByContactSource) {
                                                return (
                                                    <Report
                                                        searchCriteria={searchCriteria}
                                                        sourceKey={contactResponseFields.CONTACT_SOURCE}
                                                        selectSources={selectContactSourceValues}
                                                    />
                                                )
                                            } else if (reportType === ReportType.agentInquiryByDataSource) {
                                                return (
                                                    <Report
                                                        searchCriteria={searchCriteria}
                                                        sourceKey="contactDataSource"
                                                        selectSources={selectPropertyValuesDataSource}
                                                    />
                                                )
                                            } else {
                                                return <Report data={data} reportsFetching={reportsFetching} searchCriteria={searchCriteria} />
                                            }
                                        }}
                                    ></Route>
                                </Switch>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ReportList
