import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Badge,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    Row,
    TabContent,
    TabPane,
    UncontrolledAccordion,
    UncontrolledDropdown,
} from 'reactstrap'
import ActivitiesViewAccordion from '../../../Components/ActivitiesViewAccordion'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../Components/AppPermissionsSwitcher/constants/actions'
import AppTopSwitcher from '../../../Components/AppTopSwitcher/AppTopSwitcher'
import BackButton from '../../../Components/BackButton/BackButton'
import DeleteModal from '../../../Components/Common/DeleteModal'
import ErrorModal from '../../../Components/Common/ErrorModal'
import FormTabNavigation from '../../../Components/Common/FormTabNavigation'
import Loader from '../../../Components/Common/Loader'
import NavigationActions from '../../../Components/Common/NavigationActions/NavigationActions'
import PageTitle from '../../../Components/Common/PageTitle'
import ConnectedPropertiesAccordion from '../../../Components/ConnectedPropertiesAccordion/ConnectedPropertiesAccordion'
import ContactProfileViewTab from '../../../Components/ContactProfileViewTab'
import DocumentViewTab from '../../../Components/DocumentViewTab/Index'
import { useContactGeneralViewData } from '../../../Components/Hooks/useContactViewHook'
import NotesViewAccordion from '../../../Components/NotesViewAccordion'
import { CONTACT_TYPE } from '../../../common/constants'
import { selectCurrentUser } from '../../../store/auth/selectors'
import { deleteContact } from '../../../store/contacts'
import { formatDateTime } from '../../../utils/general'
import contacts from '../../../api/contacts/hooks'
import { History, RouteParams } from '../../../api/globalTypes'
import { contactViewTabs } from '../constants'
import { ViewData } from './ContactView.types'
import InDepthAnalysisList from '../../../Components/InDepthAnalysis/InDepthAnalysisList/InDepthAnalysisList'

const ContactView = () => {
    const history: History = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams<RouteParams>()

    const refreshToken = localStorage.getItem('refresh_token') as string

    const { isLoading: fetchingContact, data: contactData } = contacts.GetContact.useQuery(id, refreshToken)

    const { currentAgentId } = useSelector((state) => ({
        currentAgentId: selectCurrentUser(state).id,
    }))

    const contact = !fetchingContact ? contactData?.item : null

    const tabs = contactViewTabs()
    const viewData: ViewData = useContactGeneralViewData(contact) as unknown as ViewData

    const [activeTab, setActiveTab] = useState<string | number | undefined>(history.location?.state?.activeTab ?? tabs[0].name)
    const [noteErrorModal, setNoteErrorModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    const handleTabChange = (tab: string) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleDeleteModal = () => {
        setDeleteModal(true)
    }

    const handleDeleteContact = () => {
        dispatch(deleteContact(id, () => history.push('/contacts')))
        setDeleteModal(false)
    }

    if (fetchingContact) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    const firstName = contact?.contactPerson?.firstName ?? ''
    const lastName = contact?.contactPerson?.lastName ?? ''
    const numberOfRows = viewData?.general?.filter((x) => x.value)?.length ?? 0

    document.title = `${firstName} ${lastName} | ${t('app.title')}`

    return (
        <div className="page-content">
            <Container fluid>
                <div className="page-title-box ps-0 d-sm-flex align-items-start mt-3">
                    <BackButton className="me-3" />
                    <div>
                        <PageTitle
                            className="px-2"
                            title={`${firstName} ${lastName}`}
                            titleBadgeComponent={<Badge color="favorite">{contact?.contactPriority?.name}</Badge>}
                        />

                        {contact?.contactStatus && (
                            <Row>
                                <Col sm="12">
                                    <div className="px-2">
                                        <span className="fw-medium">{t('app.common.contact.profileStatus')} </span>
                                        {contact?.contactStatus?.name}
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {contact?.initialContactDate && (
                            <Row>
                                <Col sm="12">
                                    <div className="px-2">
                                        <span className="fw-medium">{t('app.common.contact.establishmentDate')}</span>{' '}
                                        {formatDateTime(contact?.initialContactDate)}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>

                <Row className="d-flex justify-content-between align-items-center mt-5 mb-3">
                    <Col sm={12} md={8}>
                        <FormTabNavigation
                            tabs={tabs}
                            activeTab={activeTab}
                            handleTabChange={handleTabChange}
                            customClass="detail-view-tabs"
                            formik={undefined}
                        >
                            <NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle href="#" className="real-estate-form-nav-link nav-link dropdown" tag="a">
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <AppPermissionsSwitcher permission="ModulePermissions" module={location} action={action.edit}>
                                            {({ hasModulePermission }: { hasModulePermission: boolean }) =>
                                                hasModulePermission && (
                                                    <>
                                                        <DropdownItem onClick={() => history.push(`/contacts/${id}/edit`)}>
                                                            <i className="mdi mdi-pencil-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.contact.action.edit')}
                                                        </DropdownItem>
                                                        <DropdownItem href="#" onClick={() => handleDeleteModal()}>
                                                            <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.contact.action.delete')}
                                                        </DropdownItem>
                                                    </>
                                                )
                                            }
                                        </AppPermissionsSwitcher>
                                        <DropdownItem
                                            onClick={() =>
                                                history.push(`/contacts/${id}/edit`, {
                                                    activeTab: 'contactProfile',
                                                    profileId: '',
                                                })
                                            }
                                        >
                                            <i className="mdi mdi-plus align-bottom me-2 text-muted"></i> {t('view.contact.action.add')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                        </FormTabNavigation>
                    </Col>
                    <Col sm={12} md={4}>
                        <NavigationActions links={contactData?.links} />
                    </Col>
                </Row>

                <TabContent activeTab={activeTab} className="text-muted">
                    {activeTab === 'general' && (
                        <TabPane tabId="general">
                            <Row>
                                <Col sm={12} xxl={3}>
                                    <Card>
                                        <CardBody>
                                            {viewData?.general &&
                                                viewData.general
                                                    .filter((viewItem) => viewItem.value)
                                                    .map((viewItem, index) => {
                                                        if (viewItem.value) {
                                                            return (
                                                                <Row
                                                                    className={`align-items-start ${numberOfRows - 1 !== index ? 'mb-4' : ''}`}
                                                                    key={viewItem?.id}
                                                                >
                                                                    <Col sm={6}>
                                                                        <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                                    </Col>
                                                                    <Col sm={6}>
                                                                        <span className="flex-grow-1 fw-light">{viewItem?.value}</span>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                        return null
                                                    })}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} xxl={9}>
                                    <UncontrolledAccordion defaultOpen={['1']} stayOpen toggle={() => null}>
                                        <AccordionItem className="mb-4">
                                            <AccordionHeader targetId="1">
                                                <h5>{t('app.common.remark')}</h5>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <Row className="mt-4">
                                                    <Col sm={12} className="fw-light text-secondary">
                                                        {contact?.notes}
                                                    </Col>
                                                </Row>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>

                                    <NotesViewAccordion id={id} noteEntity={'contact'} currentAgentId={currentAgentId} />

                                    <AppTopSwitcher
                                        feature="ActivitiesAccordion"
                                        permission="ModulePermissions"
                                        permissionName={'module.activity.view'}
                                        module={location}
                                        action={action.view}
                                    >
                                        {(props: { featureActivitiesAccordion: unknown; hasModulePermission: unknown }) => {
                                            const { featureActivitiesAccordion, hasModulePermission: permissionActivitiesAccordion } = props
                                            const allowAccordion = featureActivitiesAccordion && permissionActivitiesAccordion

                                            return (
                                                allowAccordion && (
                                                    <ActivitiesViewAccordion contactType={CONTACT_TYPE.PERSON} parentId={id} module={location} />
                                                )
                                            )
                                        }}
                                    </AppTopSwitcher>

                                    <ConnectedPropertiesAccordion contact={contact} />
                                </Col>
                            </Row>
                        </TabPane>
                    )}
                    {activeTab === 'contactProfile' && (
                        <TabPane tabId="contactProfile">
                            <ContactProfileViewTab id={id} contactType={CONTACT_TYPE.PERSON} />
                        </TabPane>
                    )}
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <Card>
                                <CardBody>
                                    <DocumentViewTab
                                        documentModule="contact"
                                        contactType={CONTACT_TYPE.PERSON}
                                        addButtonAction={() => {
                                            history.push(`/contacts/${id}/edit`, { activeTab: 'documents' })
                                        }}
                                        openDocumentFormAdd={undefined}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                    {activeTab === 'inDepthAnalysis' && (
                        <TabPane tabId="inDepthAnalysis">
                            <Card>
                                <CardBody>
                                    <InDepthAnalysisList />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                </TabContent>
                <div style={{ clear: 'both' }}></div>
            </Container>

            <ErrorModal show={noteErrorModal} onCloseClick={() => setNoteErrorModal(false)} title="Uups!" text={undefined}>
                <p>{t('modal.error.message')}</p>
            </ErrorModal>

            <DeleteModal
                show={deleteModal}
                module={'contact'}
                onDeleteClick={() => handleDeleteContact()}
                onCloseClick={() => setDeleteModal(false)}
            />
        </div>
    )
}

export default ContactView
