import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledAccordion,
} from 'reactstrap'

import QuickViewModal from '../../Components/Modals/QuickViewModal'
import { fetchPropertiesNoStore } from '../../store/properties/actions'
import TableContainer from '../Table/TableContainer'
import TableColumns from './TableColumns/TableColumns'

const ConnectedPropertiesAccordion = (props) => {
    const { contact } = props

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [connectedProperties, setConnectedProperties] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [dropDownText, setDropDownText] = useState(t('accordion.connectedProperties.dropdown.placeholder'))
    const [filterProperties, setFilterProperties] = useState(0)
    const [filterActivities, setFilterActivities] = useState(0)
    const [quickViewModal, setQuickViewModal] = useState(false)
    const [quickViewData, setQuickViewData] = useState(null)

    const toggle = () => setDropdownOpen((prevState) => !prevState)

    const toggleQuickViewModal = (data) => {
        if (quickViewModal) {
            setQuickViewModal(false)
            setQuickViewData(null)
        } else {
            setQuickViewModal(true)
            setQuickViewData(data)
        }
    }

    const columns = TableColumns(toggleQuickViewModal)

    useEffect(() => {
        if (connectedProperties.length === 0) {
            dispatch(
                fetchPropertiesNoStore(
                    {
                        noLimit: 1,
                        criteria: {
                            owner: contact?.id,
                        },
                    },
                    (data) => {
                        setConnectedProperties([...connectedProperties, ...(data?.data?.items || [])])
                    }
                )
            )

            const contactActivities = contact.activities?.filter((item) => item.property.length > 0)

            contactActivities?.forEach((activity) => {
                activity.property?.forEach((property) => {
                    connectedProperties.push({ ...property, activityType: activity.activityType, activityDateFrom: activity.dateFrom })
                })
            })

            setIsLoading(false)
        }
    }, [contact])

    return (
        <>
            <UncontrolledAccordion defaultOpen={['1']} stayOpen>
                <AccordionItem className="mb-4">
                    <div className="position-relative">
                        <AccordionHeader targetId="1">
                            <h5 className="card-title my-2">{t('app.common.relatedProperties')}</h5>
                        </AccordionHeader>
                        <div className="accordion-header-actions connected-properties-actions">
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggle}
                                direction="down"
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <DropdownToggle tag="button" className="btn btn-light border border-2 d-flex justify-content-between">
                                    {dropDownText} <i className="mdi mdi-chevron-down ms-2"></i>
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    {connectedProperties.filter((item) => !item.activityType && item.propertyService.id === 1).length > 0 && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                setFilterProperties(1)
                                                setFilterActivities(0)
                                                setDropDownText(e.currentTarget.textContent)
                                            }}
                                        >
                                            {t('accordion.connectedProperties.dropdown.sales')}
                                        </DropdownItem>
                                    )}

                                    {connectedProperties.filter((item) => !item.activityType && item.propertyService.id === 2).length > 0 && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                setFilterProperties(2)
                                                setFilterActivities(0)
                                                setDropDownText(e.currentTarget.textContent)
                                            }}
                                        >
                                            {t('accordion.connectedProperties.dropdown.lease')}
                                        </DropdownItem>
                                    )}
                                    {connectedProperties.filter((item) => item.activityType && item.propertyService.id === 1).length > 0 && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                setFilterProperties(0)
                                                setFilterActivities(1)
                                                setDropDownText(e.currentTarget.textContent)
                                            }}
                                        >
                                            {t('accordion.connectedProperties.dropdown.purchase')}
                                        </DropdownItem>
                                    )}
                                    {connectedProperties.filter((item) => item.activityType && item.propertyService.id === 2).length > 0 && (
                                        <DropdownItem
                                            onClick={(e) => {
                                                setFilterProperties(0)
                                                setFilterActivities(2)
                                                setDropDownText(e.currentTarget.textContent)
                                            }}
                                        >
                                            {t('accordion.connectedProperties.dropdown.rent')}
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <AccordionBody accordionId="1">
                        <TableContainer
                            hidePagination={true}
                            columns={columns}
                            data={
                                connectedProperties.filter((item) => {
                                    return filterProperties === 0
                                        ? filterActivities !== 0
                                            ? item.activityType && item.propertyService.id === filterActivities
                                            : item
                                        : !item.activityType && item.propertyService.id === filterProperties
                                }) || []
                            }
                            className="custom-header-css"
                            theadClass={'dmn-table-header'}
                            isLoading={isLoading}
                            noResultMessage={t('table.noResultConnectedProperties')}
                            tableHash={'connectedProperties'}
                        />
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>

            <QuickViewModal isOpen={quickViewModal} toggle={toggleQuickViewModal} data={quickViewData} />
        </>
    )
}

export default ConnectedPropertiesAccordion
