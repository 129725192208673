import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { CellProps } from './TableColumns.types'
import dayjs from 'dayjs'
import { RouteParams } from '../../../../api/globalTypes'

const TableColumns = (handlePrint: (id: number) => void, onDelete: (id: number) => void) => {
    const Link = RouterLink as unknown as React.FC<{
        children: React.ReactNode
        title: string
        to: string
        className: string
        onClick?: () => void
    }>

    const { id } = useParams<RouteParams>()

    const riskAssessment = {
        none: { risk: 'NONE', text: 'Nema', color: '#878a99' },
        low: { risk: 'LOW', text: 'Niski', color: '#0ab39c' },
        medium: { risk: 'MEDIUM', text: 'Srednji', color: '#f7b84b' },
        heigh: { risk: 'HIGH', text: 'Visoki', color: '#e82c2c' },
    }

    const { t } = useTranslation()

    const columns = [
        {
            Header: t('app.common.id'),
            accessor: 'id',
            filterable: true,
        },
        {
            Header: 'Ime i prezime agenta',
            id: `agent`,
            Cell: (cellProps: CellProps) => (
                <span>
                    {cellProps.row.original.agent.firstName} {cellProps.row.original.agent.lastName}
                </span>
            ),
            filterable: true,
        },
        {
            Header: 'Procjena rizika',
            id: `riskAssessment`,
            Cell: (cellProps: CellProps) => {
                switch (cellProps.row.original.riskAssessment) {
                    case 'NONE':
                        return (
                            <span className="fs-12 fw-medium badge" style={{ backgroundColor: riskAssessment.none.color }}>
                                {riskAssessment.none.text}
                            </span>
                        )
                    case 'LOW':
                        return (
                            <span className="fs-12 fw-medium badge" style={{ backgroundColor: riskAssessment.low.color }}>
                                {riskAssessment.low.text}
                            </span>
                        )
                    case 'MEDIUM':
                        return (
                            <span className="fs-12 fw-medium badge" style={{ backgroundColor: riskAssessment.medium.color }}>
                                {riskAssessment.medium.text}
                            </span>
                        )
                    case 'HIGH':
                        return (
                            <span className="fs-12 fw-medium badge" style={{ backgroundColor: riskAssessment.heigh.color }}>
                                {riskAssessment.heigh.text}
                            </span>
                        )
                }
            },
            filterable: true,
        },
        {
            Header: t('app.common.creationDate'),
            id: `created`,
            Cell: (cellProps: CellProps) => dayjs(cellProps.row.original?.created).format('DD.MM.YYYY., HH:mm:ss'),
            filterable: true,
        },
        {
            Header: t('app.common.updated'),
            id: `updated`,
            Cell: (cellProps: CellProps) => dayjs(cellProps.row.original?.updated).format('DD.MM.YYYY., HH:mm:ss'),
            filterable: true,
        },
        {
            id: 'actions',
            thDivClass: 'text-center',
            Cell: (cellProps: CellProps) => {
                return (
                    <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                        <li className="list-inline-item">
                            <span
                                title={t('app.common.print.title')}
                                className="text-info d-inline-block cursor-pointer"
                                onClick={() => handlePrint(cellProps.row.original?.id)}
                            >
                                <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i>
                            </span>
                        </li>

                        <li className="list-inline-item edit">
                            <Link
                                title={t('button.edit')}
                                to={`/contacts/${id}/indepthanalysis/createEdit?edit=true&id=${cellProps.row.original?.id}`}
                                className="text-info d-inline-block edit-item-btn"
                            >
                                <i className="mdi mdi-pencil-outline fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item edit">
                            <Link
                                title={t('button.delete')}
                                to="#"
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={() => onDelete(cellProps.row.original?.id)}
                            >
                                <i className="mdi mdi-trash-can-outline fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                )
            },
        },
    ]

    return columns
}

export default TableColumns
