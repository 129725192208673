import TableContainer from '../../Table/TableContainer'
import inDepthAnalysis from '../../../api/inDepthAnalysis/hooks'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TableColumns from './TableColumns/TableColumns'
import { ColumnOrder, RouteParams } from '../../../api/globalTypes'
import { useParams } from 'react-router-dom'
import InDepthAnalysisPdfModal from './InDepthAnalysisPdfModal/InDepthAnalysisPdfModal'
import { useRef, useState } from 'react'
import DeleteModal from '../../Common/DeleteModal'

const InDepthAnalysisList = () => {
    const { t } = useTranslation()
    const { id } = useParams<RouteParams>()
    const [printModalOpen, setPrintModalOpen] = useState(false)
    const [rowId, setRowId] = useState<number>(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [analysisParams, setAnalysisParams] = useState({
        page: 1,
        limit: 20,
        order: {} as ColumnOrder,
    })
    const formSubmissionId = useRef('')

    const refreshToken = localStorage.getItem('refresh_token') as string

    const { isLoading: fetchingForms, data: formData } = inDepthAnalysis.GetFormsSubmission.useQuery(id, analysisParams, refreshToken)
    const { isLoading: fetchingPdf, data: pdfData, isError } = inDepthAnalysis.GetAnalysisPdf.useQuery(formSubmissionId.current, refreshToken)
    const { mutate: deleteMutate } = inDepthAnalysis.DeleteFormsSubmission.useMutation(id)

    const formList = !fetchingForms ? formData?.items : []

    const handlePrint = (id: number) => {
        formSubmissionId.current = id.toString()
        setPrintModalOpen(!printModalOpen)
    }

    const handleDataFetch = (page: number, limit: number, order: ColumnOrder) => {
        setAnalysisParams({ page, limit, order })
    }

    const onClickDelete = (id: number) => {
        setRowId(id)
        setDeleteModal(true)
    }

    const handleDeleteFormsSubmission = () => {
        deleteMutate(rowId)
        setDeleteModal(false)
    }

    const handlePrintModal = () => {
        setPrintModalOpen(!printModalOpen)
    }

    const columns = TableColumns(handlePrint, onClickDelete)

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <Button tag={Link as React.ElementType} to={`/contacts/${id}/indepthanalysis/createEdit`} color="add" className="btn-label">
                <i className="mdi mdi-plus align-bottom label-icon align-middle fs-16 me-2"></i>
                {t('button.add')}
            </Button>
        </div>
    )

    return (
        <>
            <TableContainer
                columns={columns}
                tableActions={tableActions}
                data={formList}
                isGlobalFilter={false}
                isAddUserList={false}
                isLoading={fetchingForms}
                handleDataFetch={handleDataFetch}
                totalCount={formData?.pagination?.totalItems}
                pageCount={formData?.pagination?.totalPages}
                currentPage={analysisParams.page}
                totalPages={formData?.pagination?.totalPages}
                theadClass={'dmn-table-header'}
                tableHash={'inDepthAnalysis'}
                hasColumnSelection={false}
                hasPageSizeSelect={false}
                permissionName={'none'}
                searchCriteria={undefined}
                handleRowClick={undefined}
            />
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteFormsSubmission()}
                onCloseClick={() => setDeleteModal(false)}
                module={undefined}
            />
            {printModalOpen && (
                <InDepthAnalysisPdfModal
                    toggle={handlePrintModal}
                    iframeSrc={pdfData}
                    isFetching={fetchingPdf}
                    isError={isError}
                    isOpen={printModalOpen}
                />
            )}
        </>
    )
}

export default InDepthAnalysisList
