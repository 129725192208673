import QueryString from 'qs'
import { apiReq } from '..'
import { ReportCriteria } from './types/global.types'

const formatCriteria = (criteria: ReportCriteria) => {
    const { agent, activityType } = criteria

    return {
        ...criteria,
        agent: agent?.map((item) => item.id),
        activityType: activityType?.map((item) => item.id),
    }
}
const formatUrlParam = (type: string) => {
    const urlMapping: Record<string, string> = {
       'agent-activities': 'activities'
    }

    return urlMapping[type] 
}

export const getReportByType = async (type: string, criteria: ReportCriteria) => {
    const typeParam = formatUrlParam(type)
    const formattedCriteria = formatCriteria(criteria)

    const response = await apiReq(`v1/report/agents/${typeParam}`, {
        params: { criteria: formattedCriteria },
        paramsSerializer: (params: ReportCriteria) => {
            return QueryString.stringify(params)
        },
    })

    return response.data
}
