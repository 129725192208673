import { Formik } from 'formik'
import * as Yup from 'yup'
import ReportFilterForm from './ReportFilterForm'

const initialValue = {
    dateFrom: '',
    dateTo: '',
    agent: [],
    activityType: [],
}

const validation = Yup.object({
    dateFrom: Yup.date().required(),
    dateTo: Yup.date().min(Yup.ref('dateFrom')).required(),
})

const ReportListFilter = ({ value, onFilterSubmit, onFilterReset }) => { 
    return (
        <Formik
            component={ReportFilterForm}
            initialValues={value || initialValue}
            validationSchema={validation}
            onSubmit={onFilterSubmit}
            onReset={onFilterReset}
        />
    )
}

export default ReportListFilter
