import { Modal, ModalBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import Loader from '../../../Common/Loader'
import { InDepthAnalysisPdfModalProps } from './InDepthAnalysisPdfModal.types'

const InDepthAnalysisPdfModal = (props: InDepthAnalysisPdfModalProps) => {
    const { isOpen = false, toggle, iframeSrc, isFetching, isError } = props
    const { t } = useTranslation()

    const renderContent = () => {
        if (isFetching) {
            return <Loader />
        }
        if (isError) {
            return (
                <div className="alert alert-danger" role="alert">
                    Došlo je do greške prilikom učitvanja PDF-a. Molimo pokušaj ponovo kasnije.
                </div>
            )
        }
        if (iframeSrc) {
            return <iframe style={{ width: '100%', height: '100%' }} title="PDF" src={iframeSrc}></iframe>
        }

        return null
    }

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                    {' '}
                    {t('modal.offerPrintPreview.title')}
                </h5>
                <div>
                    <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={toggle}></button>
                </div>
            </div>
            <ModalBody>
                <div style={{ height: '600px' }}>{renderContent()}</div>
            </ModalBody>
        </Modal>
    )
}

export default InDepthAnalysisPdfModal
