import classNames from 'classnames'
import dayjs from 'dayjs'
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Col, Label, Row } from 'reactstrap'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import { selectOmittedActivityValuesType } from '../../store/activityValues'
import { selectAgentsFormOption } from '../../store/agents'

const ReportFilterForm = ({ values, setFieldValue, errors, touched, handleSubmit, handleReset }) => {
    const { t } = useTranslation()

    const datePickerClassNames = (fieldName) =>
        classNames('form-control', {
            'is-invalid': errors[fieldName] && touched[fieldName],
        })

    const { agents, activityTypes } = useSelector((state) => ({
        agents: selectAgentsFormOption(state),
        activityTypes: selectOmittedActivityValuesType(state),
    }))

    const selectData = [
        {
            label: t('reports.filter.agents'),
            fieldValue: 'agent',
            options: agents.map((agent) => ({
                value: agent.value,
                label: agent.label,
            })),
        },
        {
            label: t('reports.filter.activityTypes'),
            fieldValue: 'activityType',
            options: activityTypes.map((type) => ({
                value: type.id,
                label: type.name,
            })),
        },
    ]

    // react-select expects options as object {value: '', label: ''}
    const getSelectValue = (fieldValue) => {
        const currentValue = values[fieldValue]
        if (!currentValue || !currentValue.length) return null

        return currentValue.map((item) => ({
            value: item.id,
            label: item.label,
        }))
    }

    return (
        <form onSubmit={handleSubmit}>
            <Row className="mb-4">
                <Col>
                    <Label for="dateFrom">{t('app.common.dateFrom')}</Label>
                    <div className="flatpickr-container">
                        <Flatpickr
                            id="dateFrom"
                            className={datePickerClassNames('dateFrom')}
                            name="dateFrom"
                            options={{
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={([date]) => {
                                setFieldValue('dateFrom', dayjs(date).format('YYYY-MM-DD'))
                            }}
                            value={values.dateFrom ? new Date(values.dateFrom) : ''}
                        />
                    </div>
                </Col>
                <Col>
                    <Label for="dateTo">{t('app.common.dateTo')}</Label>
                    <div className="flatpickr-container">
                        <Flatpickr
                            id="dateTo"
                            className={datePickerClassNames('dateTo')}
                            name="dateTo"
                            options={{
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={([date]) => {
                                setFieldValue('dateTo', dayjs(date).format('YYYY-MM-DD'))
                            }}
                            value={values.dateTo ? new Date(values.dateTo) : ''}
                        />
                    </div>
                </Col>
                {selectData.map((data, index) => (
                    <div key={index}>
                        <Label for="agents" className="mt-3">
                            {data.label}
                        </Label>

                        <Select
                            onChange={(e) => {
                                const formikValues = e
                                    ? e.map((option) => ({
                                          id: option.value,
                                          label: option.label,
                                      }))
                                    : []

                                setFieldValue(`${data.fieldValue}`, formikValues)
                            }}
                            options={data.options}
                            isMulti={true}
                            name={data.fieldValue}
                            id={`${data.fieldValue}-filter`}
                            value={getSelectValue(data.fieldValue)}
                            noOptionsMessage={() => t('form.common.select.noData')}
                            placeholder=""
                        />
                    </div>
                ))}
            </Row>

            <FormActionButtons hasCancel cancelAction={handleReset} />
        </form>
    )
}

export default ReportFilterForm
