import dayjs from 'dayjs'
import { getIn } from 'formik'
import { camelCase } from 'lodash'
import { CONTACT_TYPE } from '../common/constants'
import { mapFields } from '../common/forms/general/fields'
import { photosFields } from '../common/forms/photos/fields'
import { projectResponseFields } from '../common/response/project'

export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
}

export function setPhotoPriorityFromIndex(index) {
    const priorityMax = 40

    return priorityMax - index
}

/**
 *
 * @param {File} file
 * @param {Object} entity project or property
 * @param {string} module project or property
 * @param {string} collection photo or ground_plan
 * @param {number} collectionCount count of existing photos, this data is needed to determine new photo priority
 * @param {string} defaultLocale current app locale
 * @returns {FormData}
 */
export function getUploadPhotoFormData(file, entityData, module, collection, collectionCount) {
    const { parent, city, widerArea } = entityData
    const altTag = `${city?.name ?? ''}, ${widerArea?.name ?? ''}`

    const locales = JSON.parse(localStorage.getItem('translationLangs')) ?? []

    const translationsArray = locales.map((lang) => ({
        locale: lang,
        fileDescription: altTag,
    }))

    const formData = new FormData()

    formData.append('file', file)
    formData.append('translations', JSON.stringify(translationsArray))
    formData.append('parent_id', parent)
    formData.append('module', module)
    formData.append('collection', collection)
    formData.append('file_sync', 1)
    formData.append(photosFields.PRIORITY, collectionCount)

    return formData
}

/**
 * @param {number} fileCount
 * @returns {number} adds weight to argument
 */
export function addPriorityWeight(fileCount) {
    return fileCount * 10
}

/**
 *
 * @param {Photo} a The first element for comparison.
 * @param {Photo} b The second element for comparison.
 * @returns {number} see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 */
export function priorityCompareFn(a, b) {
    if (!a.priority && b.priority) return 1
    if (a.priority && !b.priority) return -1
    if (a.priority < b.priority) return -1
    if (a.priority > b.priority) return 1
    return 0
}

export function idCompareFn(a, b) {
    if (a.id > b.id) return -1
    if (a.id < b.id) return 1
    return 0
}

export function embedYotubeVideo(value) {
    return `https://www.youtube.com/embed/${getVideoId(value)}`
}

/**
 *
 * @param {string} rawUrl can either be full youtube link or just youtube id, ex. https://www.youtube.com/watch?v={ID} or ID
 * @returns youtube video ID
 */
export function getVideoId(rawUrl) {
    try {
        const url = new URL(rawUrl)
        return url.searchParams.get('v')
    } catch (error) {
        return rawUrl
    }
}

/**
 *
 * @param {Object} obj
 * @returns Array of validation errors
 */
export function transformValidationErrors(obj, key) {
    if (!obj || typeof obj === 'boolean' || typeof obj === 'number') {
        return null
    }

    if (typeof obj === 'string') {
        return obj
    }

    const values = Object.entries(obj)

    return values.reduce((acc, [k, v]) => {
        const _key = key ? `${key}.${k}` : k
        const recursive = transformValidationErrors(v, _key)

        if (recursive) {
            if (typeof recursive === 'string') {
                const endKey = _key
                    .split('.')
                    .reverse()
                    .find((s) => !['hr', 'en'].includes(s))
                return acc.concat({ key: endKey, value: recursive })
            } else {
                return acc.concat(recursive)
            }
        }

        return acc
    }, [])
}

/**
 *
 * @param {Object} input
 * @returns Flat field types
 */
export function findFieldTypes({ sideForm, mainForm }) {
    const inputs = [...(sideForm || []), ...(mainForm || [])]

    return inputs.reduce((acc, curr) => {
        if (!curr) return acc

        if (curr.children) {
            return acc.concat(curr.children)
        }

        return acc.concat(curr)
    }, [])
}

/**
 *
 * @param {Array} inputs
 * @returns {Array} Array of field types
 */
export function getFieldTypes(inputs) {
    return inputs?.reduce((acc, curr) => {
        if (curr.length) {
            return acc.concat(curr)
        }

        return acc.concat(findFieldTypes(curr))
    }, [])
}

/**
 * Check with loose equality if location sync type is set to approximate location (=2)
 *
 * @param Object formValue
 * @returns boolean if map location sync type is 2
 */
export function isApproxLocationSyncType(formValue) {
    if (!formValue) {
        return undefined
    }

    const lookupKey = mapFields.MAP_LOCATION_SYNC_TYPE
    // Two keys are defined because payload and response field have different naming conventions
    const lookupKeys = [lookupKey, camelCase(lookupKey)]

    // eslint-disable-next-line eqeqeq
    return lookupKeys.some((key) => getIn(formValue, key) == 2)
}

/**
 * Coerce lat lng coordinates to number
 *
 * @param {Object} entity - can be property or project
 * @returns lat lng coerced coordinates
 */
export function coerceLatLng(entity) {
    if (!entity) {
        return undefined
    }

    return {
        lat: +entity[projectResponseFields.MAP_LATITUDE],
        lng: +entity[projectResponseFields.MAP_LONGITUDE],
    }
}

/**
 * Replaces specified characters in a string and returns the modified value as a float.
 * @param {string} value - The input string.
 * @returns {number} The modified string as a float.
 */
export function convertNumeralToFloatString(value) {
    return value?.toString().replace(/\./g, '').replace(/,/g, '.')
}

export function createSelectOption(label, value) {
    return {
        label,
        value: `${value}`,
    }
}

/**
 * Util function to return selected contact as options
 * @param {Object} contact
 * @returns single select option in order to show initial select value
 */
export function contactOptions(contact) {
    if (contact?.id) {
        if (contact.contactType === CONTACT_TYPE.PERSON) {
            const optionLabel = contact?.contactPerson?.firstName + ' ' + contact?.contactPerson?.lastName

            return [createSelectOption(optionLabel, contact?.id)]
        }

        return [createSelectOption(contact?.contactCompany?.name, contact?.id)]
    }
}

/**
 * @param {Object} entity Project or property
 * @returns entity's translated title or default fallback
 */
export const findEntityTitle = (entity, field, appLocale = 'hr') => {
    const translatedTitle = entity?.translations?.[appLocale]?.[field]
    const backupTitle = [entity?.propertySubType?.name, entity?.propertyService?.name, entity?.city?.name, entity?.widerArea?.name]
        .filter(Boolean)
        .join(', ')

    if (Boolean(translatedTitle) && translatedTitle !== '') {
        return translatedTitle
    }

    return entity?.[field] ?? backupTitle
}

export const generateLocationData = (item) => {
    if (!item) return

    return `${item.street ? item.street + ', ' : ''}${item.streetNumber ? item.streetNumber + ', ' : ''}${item.city.name}, ${item.country.name}`
}

export const changeTranslationObject = (object, title, description, offer_description) => {
    const translations = []

    const locales = Object.keys(object?.[title])

    locales.forEach((locale) => {
        translations.push(
            offer_description
                ? {
                      locale,
                      [title]: object?.[title][locale],
                      [description]: object?.[description][locale],
                      [offer_description]: object?.[offer_description][locale],
                  }
                : {
                      locale,
                      [title]: object?.[title][locale],
                      [description]: object?.[description][locale],
                  }
        )
    })

    return translations
}

/**
 * Recursive function that returns either a single value or a list of values
 * @param {Object} company
 * @returns string value or list of string values
 */
export function resolveValueByType(data, accessor) {
    if (data?.id) {
        return data.id.toString() ?? ''
    }

    if (data?.length > 0) {
        if (accessor) {
            return data.map((dataItem) => ({ [accessor]: resolveValueByType(dataItem) }))
        }

        return data.map(resolveValueByType)
    }

    return [{ [accessor]: '' }]
}

export function selectStateOwnerFormOptions(data) {
    if (data?.length > 0) {
        // Using flatMap here because contactOptions returns a list of a single item
        return data.flatMap(contactOptions)
    }

    if (data) {
        return contactOptions(data)
    }

    return []
}

/**
 * Formats a number to have two decimal places and applies the 'hr-HR' locale.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} - The formatted number as a string with two decimal places and 'hr-HR' locale.
 * @throws {Error} - If the input is not a valid number or if it's not provided.
 */
export function formatNumberWithTwoDecimals(number) {
    if (typeof number !== 'number' || isNaN(number)) {
        return ''
    }

    return number.toLocaleString('hr-HR', { minimumFractionDigits: 2 })
}

export const formatDateTime = (date, showTime = false) => {
    const formatString = showTime ? 'DD.MM.YYYY. HH:mm' : 'DD.MM.YYYY.'
    const formatedDate = date && dayjs(date)?.format(formatString)
    return formatedDate
}

export const getUrlBase = (key) => {
    let url

    switch (key) {
        case 'projects':
            url = 'project'
            break
        case 'properties':
            url = 'property'
            break
        case 'contacts':
            url = 'contact'
            break
        case 'companies':
            url = 'company'
            break
        case 'activities':
            url = 'activity'
            break
        case 'documents':
            url = 'document'
            break
        default:
            console.error(`There is no page with this ${key}`)
    }

    return url
}

export const uniqueObjectsInArray = (array, property) => {
    return Array.from(new Set(array?.map((a) => a?.[property])))?.map((id) => {
        return array?.find((a) => a?.[property] === id)
    })
}

export const convertDateStringToTimeStamp = (strDate) => {
    const date = Date.parse(strDate)
    return date
}
