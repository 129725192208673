import { dataFallback } from '../constants'

export const handleChartHeight = (numObjects, numFields) => {
    const baseHeight = 200
    const heightPerObject = 50
    const heightPerField = 40

    const totalHeight = baseHeight + numObjects * heightPerObject + numFields * heightPerField

    return totalHeight
}


export function totalReducer(acc, curr) {
    return acc + curr.count
}

export function getAgentActivitiesReportSum(reports) {
    const activities = reports?.flatMap((report) => report?.activities)

    return activities?.reduce((acc, activity) => {
        const existingActivity = acc?.find((item) => item.id === activity.id)

        if (existingActivity) {
            existingActivity.count += activity.count
        } else {
            acc.push({
                id: activity?.id,
                name: activity?.name,
                count: activity?.count,
            })
        }

        return acc
    }, [])
}

export function findPropertySupplySourceByName(sources, sourceName) {
    return sources?.find((source) => source.name === sourceName)
}

export function renderPropertySupplySource(source) {
    if (!source) return dataFallback

    const values = Object.values(source)
    return values.join(' / ')
}

export function totalPropertySupplySourceByName(reports, sourceName) {
    return reports.reduce((total, { propertySupplySource }) => {
        const source = findPropertySupplySourceByName(propertySupplySource, sourceName)

        if (!source) return total

        // Remove name from object
        const { rest } = source

        const values = Object.values(rest)

        if (!total) return values

        return total.map((totalItem, i) => totalItem + values[i])
    }, null)
}

export function getAgentInquirySourceTotals(sources, reports, key) {
    if (!sources || !reports) return null

    return sources.map(({ id }) => {
        const total = reports.reduce((acc, { [key]: source }) => {
            console.log('source', source)
            const sourceValue = source?.[id]

            if (!sourceValue) return acc

            return acc + sourceValue
        }, 0)
        return total
    })
}
